.widget-space--spaceWidget--1L3eH {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  overflow: hidden;
  font-size: 16px;
  font-weight: 200;
  background: #fff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;


  /* IE 11 scrollbar styling workarounds */
  scrollbar-face-color: #E0E0E0;
  scrollbar-arrow-color: #E0E0E0;
  scrollbar-shadow-color: white;
  scrollbar-track-color: white;
}

.widget-space--activityComponentWrapper--1-a6y {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.widget-space--activityComponentWrapper--1-a6y > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: inherit;
}

.widget-space--widgetBody--M20il {
  position: relative;
  z-index: 10;
  width: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}

.widget-space--hidden--25aKB {
  visibility: hidden;
}

.widget-space--spaceWidget--1L3eH .widget-space--messageButton--2V6F1 {
  background-color: #07c1e4;
}

.widget-space--spaceWidget--1L3eH .widget-space--meetButton--1CU0y {
  background-color: #30d557;
}

.widget-space--spaceWidget--1L3eH .widget-space--peopleButton--J6uJk {
  background-color: #00d6a2;
}

.widget-space--spaceWidget--1L3eH .widget-space--filesButton--2BJ48 {
  background-color: #ffb400;
}

.widget-space--activityMenuButtonWrapper--1dipC {
  padding: 0 16px;
}

.widget-space--tabContainer--1JjNT {
  display: grid;
  grid-template-columns: repeat(3, auto) 1fr;
  grid-template-areas:
    'messages content people huddle';
  grid-gap: 8px;
  gap: 8px;
  border-bottom: .99px solid rgb(236, 236, 237);
  height: 48px;
  padding-left: 16px;
  padding-right: 16px;
}

.widget-space--tabMeet--3jr1H {
  height: 100%;
  align-self: end;
  justify-self: end;
  place-self: end;
  grid-area: huddle;
  display: grid;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#widget-space--huddle--25Xoo {
  background-color: #00853C;
}

#widget-space--huddle--25Xoo:hover {
  background-color: #03612C;
}

#widget-space--huddle--25Xoo:active {
  background-color: #08421F;
}

.widget-space--tabOther--3hOMP {
  background: transparent;
  -webkit-box-shadow: 0px 0px 0px transparent;
          box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  cursor: pointer;
  font-size: 14px;
  color: #545454;
  font-family: 'CiscoSansTT Regular';
  margin-top: 12px;
  padding: 0px 8px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.widget-space--tabOther--3hOMP:disabled {
  background: transparent;
  -webkit-box-shadow: 0px 0px 0px transparent;
          box-shadow: 0px 0px 0px transparent;
  text-shadow: 0px 0px 0px transparent;
}

.widget-space--tabActive--1Gg3D {
  border-bottom: 2px solid #121212;
  margin-bottom: -2px;
  color: #121212;
}

.widget-space--activityMenuButton--1_7hp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.widget-space--activityMenuWrapper--s6SKR {
  position: absolute;
  top: 1px;
  right: 1px;
  z-index: 90;
  width: 280px;
  height: 100%;
  overflow: hidden;
  -webkit-box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
  -webkit-animation: widget-space--slideIn--3nBld 350ms;
          animation: widget-space--slideIn--3nBld 350ms;
}

.widget-space--callTimer--38q35 {
  margin-left: 10px;
  font-size: 12px;
  color: #858688;
  display: none;
}

.widget-space--errorWrapper--2rsUO {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
}

.widget-space--secondaryWidget--179eZ {
  position: absolute;
  top: 1px;
  right: 1px;
  z-index:  80;
  height: 100%;
  overflow: hidden;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
}

.widget-space--secondaryWidgetCover--F2Ake {
  width: 280px;
}

.widget-space--secondaryWidgetFull--PtcNj {
  width: 100%;
}

.widget-space--secondaryWidget--179eZ > div {
  height: 100%;
}

@-webkit-keyframes widget-space--slideIn--3nBld {
  from {
    right: -50%;
  }

  to {
    right: 0;
  }
}

@keyframes widget-space--slideIn--3nBld {
  from {
    right: -50%;
  }

  to {
    right: 0;
  }
}
